import { Head, Link } from "@inertiajs/react";

import { Button, buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { AuthForm } from "@/components/forms/auth-form";
import { Icon } from "@/components/Icon";
import { useTheme } from "@/providers/ThemeProvider";

const DevelopmentLogins = () => {
  const [input, setInput] = useState("2066");
  const { theme, toggleTheme } = useTheme();
  if (!import.meta.env.DEV) {
    return;
  }

  return (
    <div className="absolute bottom-1 px-2 w-full flex justify-between gap-2 h-10 items-center z-10">
      <Input
        className={"flex-1"}
        value={input}
        onChange={(v) => setInput(v.target.value)}
      />
      <Button className={"shrink-0"} asChild>
        <Link
          href={route("loginLinkLogin", {
            key: input,
          })}
          as="button"
          method="post"
        >
          LOGIN AS {input}
        </Link>
      </Button>
      <Button
        className={"shrink-0"}
        size={"icon"}
        variant="secondary"
        onClick={() => toggleTheme()}
      >
        {theme === "light" ? (
          <Icon icon={"fa-moon"} iconStyle="SOLID" />
        ) : (
          <Icon icon={"fa-sun-bright"} />
        )}
      </Button>
    </div>
  );
};

export default function Login() {
  return (
    <div className="h-full">
      <Head title={"Login"} />
      <div className="absolute right-4 top-4 flex justify-between gap-2 h-10 items-center z-10">
        <Link
          href={route("register")}
          className={cn(buttonVariants({ variant: "ghost" }))}
        >
          Sign up
        </Link>
      </div>

      <DevelopmentLogins />

      <div className="container relative h-full grid place-items-center">
        <div className="mx-auto flex w-full flex-col space-y-6 h-72">
          <AuthForm />
        </div>
      </div>
    </div>
  );
}
